var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"login-wrapper"},[_c('div',{staticClass:"form-wrapper"},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',{ref:"form",attrs:{"form":_vm.form,"layout":"vertical","label-col":{ span: 24 },"wrapper-col":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"MerchantNo"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'merchantNo',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your MerchantNo!'
                    }
                  ]
                }
              ]),expression:"[\n                'merchantNo',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input your MerchantNo!'\n                    }\n                  ]\n                }\n              ]"}],attrs:{"size":"large"}})],1),_c('a-form-item',{attrs:{"label":"SecretKey"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'secretKey',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your SecretKey!'
                    }
                  ]
                }
              ]),expression:"[\n                'secretKey',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input your SecretKey!'\n                    }\n                  ]\n                }\n              ]"}],attrs:{"size":"large"}})],1)],1)],1)],1),_c('div',{staticClass:"button-wrap"},[_c('a-button',{staticClass:"submit-btn",attrs:{"type":"link","size":"large","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v(" 激活 ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }