<template>
  <div class="container">
    <div class="login-wrapper">
      <div class="form-wrapper">
        <a-spin :spinning="loading">
          <a-form
            ref="form"
            :form="form"
            layout="vertical"
            :label-col="{ span: 24 }"
            :wrapper-col="{ span: 24 }"
          >
            <a-form-item label="MerchantNo">
              <a-input
                size="large"
                v-decorator="[
                  'merchantNo',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your MerchantNo!'
                      }
                    ]
                  }
                ]"
              />
            </a-form-item>
            <a-form-item label="SecretKey">
              <a-input-password
                size="large"
                v-decorator="[
                  'secretKey',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your SecretKey!'
                      }
                    ]
                  }
                ]"
              />
            </a-form-item>
          </a-form>
        </a-spin>
      </div>
      <div class="button-wrap">
        <a-button
          type="link"
          class="submit-btn"
          size="large"
          :loading="loading"
          @click="handleSubmit"
        >
          激活
        </a-button>
      </div>
    </div>
  </div>
</template>
<script>
import { postMyshopline } from '@/views/home/api'
import Client, { shared, Redirect } from '@shoplinedev/appbridge'
export default {
  components: {},
  data() {
    return {
      form: this.$form.createForm(this, { name: 'coordinated' }),
      loading: false,
      redirect: null
    }
  },
  created() {},
  mounted() {
    const app = Client.createApp({
      appKey: 'e18d8fa41106981485e65d10676a7af93b34b8e3',
      host: shared.getHost()
    })
    this.redirect = Redirect.create(app)
    if (this.$route.query) {
      this.form.setFieldsValue({
        merchantNo: this.$route.query.merchantNo,
        secretKey: this.$route.query.secretKey
      })
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.loading = true;
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = { ...this.$route.query, ...values }
          postMyshopline(params)
            .then((res) => {
              this.redirect.replaceTo(res.data.redirectUrl)
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/styles/common.less';

.container {
  height: 100%;
  background: rgb(234, 237, 241);
}
.login-wrapper {
  width: 100%;
  max-width: 880px;
  margin: 0 auto;
  padding-top: 50px;
}
.form-wrapper {
  padding: 20px 24px;
  background-color: @white;
  border-radius: 6px;
  margin-top: 100px;
}
// .input-item {
//   display: block;
//   width: 100%;
//   margin-bottom: 20px;
//   border: 0;
//   padding: 10px;
//   border-bottom: 1px solid rgb(128, 125, 125);
//   font-size: 15px;
//   outline: none;
// }
// .input-item:placeholder {
//   text-transform: uppercase;
// }
.button-wrap {
  text-align: right;
}
.submit-btn {
  text-align: center;
  width: 10%;
  border: 0px;
  margin-top: 40px;
  background-image: linear-gradient(to right, @bg-link-color, @bg-link-color);
  color: #fff;
}

a {
  text-decoration-line: none;
  color: #abc1ee;
}
</style>
